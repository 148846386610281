<template>
  <div>
    <div class="header hidden-sm-and-down">
      <img src="../assets/report/icon_report.svg" />
      <h2>問題回報</h2>
    </div>
    <div class="report_container">
      <div class="main">
        <!-- 進入管道 -->
        <Step0 v-if="step == 0" @step0="step0($event)">
          <Stepper class="hidden-md-and-up" :step="step" />
        </Step0>
        <!-- 問題描述 -->
        <Step1
          v-if="step == 1"
          @step1="step1($event)"
          :data="step1_Data"
          @toFocus="toFocus($event)"
          ref="userdata"
        >
          <Stepper class="hidden-md-and-up" :step="step" />
        </Step1>
        <input
          v-for="item in 4"
          accept="image/gif, image/jpeg, image/png"
          :ref="`file${item}`"
          type="file"
          :id="`screenshot${item}`"
          @change="$refs.userdata.updateMethods[`${item}`]($event)"
          :key="item"
        />
        <!-- 星盤資料 -->
        <Step2
          v-if="step == 2"
          @step2="step2($event)"
          :data="step2_Data"
          :DontKnow="DontKnow"
          ref="user"
        >
          <Stepper class="hidden-md-and-up" :step="step" />
        </Step2>

        <!-- 聯絡資料 -->
        <Step3
          v-if="step == 3"
          @step3="step3($event)"
          :data="step3_Data"
          ref="contact"
        >
          <Stepper class="hidden-md-and-up" :step="step" />
        </Step3>
      </div>
      <div class="side">
        <img src="../assets/report/side.png" />
        <Stepper class="side_stepper" :step="step" />
      </div>
    </div>
    <SendEmailResult
      v-if="openSendEmailResult"
      text="謝謝您的回報，我們將盡快為您優化"
      direction="/"
    />
  </div>
</template>
<script>
import Step0 from "../components/Report/step0_enter";
import Step1 from "../components/Report/step1_description";
import Step2 from "../components/Report/step2_user";
import Step3 from "../components/Report/step3_contact";
import Stepper from "../components/Report/stepper"; //進度表
import SendEmailResult from "../components/SendEmailResult";

export default {
  components: {
    Step0,
    Step1,
    Step2,
    Step3,
    Stepper, //進度表
    SendEmailResult,
  },
  data() {
    return {
      step: 0,
      //進入管道(瀏覽器)
      enter: "Line",
      //1.問題描述
      step1_Data: {
        loginMethod: "Line",
        type: "無法登入",
        imgArray: {
          UpdateImg1: null,
          UpdateImg2: null,
          UpdateImg3: null,
          UpdateImg4: null,
        },
        detail: "",
      },
      //2.星盤資料
      step2_Data: {
        Name: "",
        BirthCountry: "台灣",
        BirthState: "",
        City: "台北",
        BirthDay: "1990-05-30",
        BirthTime: "12:00",
        IsDaylight: false,
        DontKnow: false,
      },
      DontKnow: false,
      //3.聯絡資料
      step3_Data: {
        contact_name: "",
        contact_email: "",
      },
      openSendEmailResult: false,
    };
  },
  computed: {},

  methods: {
    step0(where) {
      this.enter = where;
      this.step = 1;
    },
    step1(data) {
      this.step1_Data.loginMethod = data.loginMethod;
      this.step1_Data.type = data.type;
      this.step1_Data.detail = data.detail;
      this.step1_Data.imgArray = data.imgArray;
      //上一步或下一步
      data.next ? this.step++ : this.step--;
    },
    step2(data) {
      this.step2_Data.Name = data.Name;
      this.step2_Data.BirthCountry = data.BirthCountry;
      this.step2_Data.BirthState = data.BirthState;
      this.step2_Data.City = data.City == "其他" ? data.BirthCity : data.City;
      this.step2_Data.BirthDay = data.BirthDay;
      this.step2_Data.BirthTime = data.BirthTime;
      this.step2_Data.IsDaylight = data.isDaylight;
      this.DontKnow = data.IDontKnow;
      //上一步或下一步
      data.next ? this.step++ : this.step--;
    },
    step3(data) {
      this.step3_Data.contact_name = data.contact_name;
      this.step3_Data.contact_email = data.contact_email;
      if (data.next) {
        //寄出Email
        this.SEND_REPORT();
      } else {
        this.step--;
      }
    },
    //框框中的十字要啟動
    toFocus($event) {
      this.$refs[`file${$event}`][0].click();
    },
    //送出Email
    SEND_REPORT() {
      let content = `
              一、問題描述：
              <ol>  
                <li>進入管道：${this.enter}</li>
                <li>登入平台：${this.step1_Data.loginMethod}</li>
                <li>問題類型：${this.step1_Data.type}</li>
                <li>詳細內容：
                  <br>${this.step1_Data.detail}</li>
              </ol> 
              二、星盤資料：
              <ol> 
              <li>會員ID：${localStorage.getItem("loginIndex")}</li>
                <li>姓名：${this.step2_Data.Name}</li>
                <li>出生日期：${this.step2_Data.BirthDay}</li>
                <li>出生時間：${this.step2_Data.BirthTime}</li>
                <li>日光節約時間：${this.step2_Data.IsDaylight}</li>
                <li>出生地點：${this.step2_Data.BirthCountry}-${
        this.step2_Data.BirthState
      }-${this.step2_Data.City}-${this.step2_Data.City}</li>
              </ol> 
              三、註冊失敗資料查找：
              <ol> 
                <li>註冊姓名：${localStorage.getItem("rn") || "無"}</li>
                <li>註冊生日：${localStorage.getItem("rb") || "無"}</li>
                <li>平台ID：${localStorage.getItem("before") || "無"}</li>
                <li>是否取得token：<br>
                  ${localStorage.getItem("token") || "未登入"}</li>
              </ol>
              四、聯絡方式：
              <ol> 
                <li>使用者名稱：${this.step3_Data.contact_name}</li>
                <li>使用者信箱：${this.step3_Data.contact_email}</li>
              </ol>
              <p style="color:#fff;">版本：0721</p> 
              `;
      var formData = new FormData();
      formData.set("Subject", this.step3_Data.contact_email);
      formData.set("Content", content);
      formData.set("FromMail", `from_address@example.com`);
      formData.set("FromName", `ASTROINFO 問題回報系統`);
      if (this.step1_Data.type === "訂單/金流問題") {
        formData.set("ToMail", `damoujessetang11@gmail.com`);
      } else {
        formData.set("ToMail", `damou-service@astrorg.com`);
      }

      // formData.set("ToMail", `joe.lin@astrorg.com`);
      formData.append("Attachments", this.$refs.file1[0].files[0]);
      formData.append("Attachments", this.$refs.file2[0].files[0]);
      formData.append("Attachments", this.$refs.file3[0].files[0]);
      formData.append("Attachments", this.$refs.file4[0].files[0]);
      this.$API.SendReport(formData).then((res) => {
        // formData.set("ToMail", `joe.lin@astrorg.com`);
        window.localStorage.removeItem("CId");
        window.localStorage.removeItem("CToken");
        window.localStorage.removeItem("status");
        window.localStorage.removeItem("errorTime");
        window.localStorage.removeItem("rn");
        window.localStorage.removeItem("rb");
        this.openSendEmailResult = true;
      });
    },
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.$API.Get_MemberInfo().then((data) => {
        window.localStorage.setItem("loginIndex", data.Data.MemberId);
        this.step2_Data = data.Data.AstrolabeFriendLists[0];
        this.step2_Data.GenderDisplay =
          this.step2_Data.Gender === "M" || this.step2_Data.Gender === "男"
            ? "男生"
            : "女生";
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
@import "@/scss/report.scss";

* {
  font-family: "Noto Sans TC";
}
.header {
  width: 100vw;
  max-width: 1068px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: 500;
  margin-top: 12px;
  letter-spacing: 3.2px;
  color: #ab956c;
  @include align(flex-start, center);
  img {
    width: 52px;
    margin-right: 16px;
  }
}
//隱藏輸入框
input {
  visibility: hidden;
  height: 0;
  width: 0;
}
</style>
