<template>
  <div class="step1 main_container">
    <h3 class="main_title">問題描述</h3>
    <p class="main_description">提供詳細的資訊能幫助我們改善服務哦！</p>
    <slot></slot>
    <hr />
    <div class="form">
      <p class="form_title">登入方式</p>
      <div class="loginMethod">
        <div
          :class="{ active: loginMethod == 'Line' }"
          @click="loginMethod = 'Line'"
        >
          LINE
        </div>
        <div
          :class="{ active: loginMethod == 'Facebook' }"
          @click="loginMethod = 'Facebook'"
        >
          Facebook
        </div>
        <div
          :class="{ active: loginMethod == 'Google' }"
          @click="loginMethod = 'Google'"
        >
          Google
        </div>
      </div>
      <p class="form_title">問題類型</p>
      <div class="type" @click="downList = true">
        {{ type }}
        <div class="content" v-if="downList">
          <div
            @click.stop="
              type = '無法登入';
              downList = false;
            "
            :class="{ active: type == '無法登入' }"
          >
            無法登入
          </div>
          <div
            @click.stop="
              type = '訂單/金流問題';
              downList = false;
            "
            :class="{ active: type == '訂單/金流問題' }"
          >
            訂單/金流問題
          </div>
          <div
            @click.stop="
              type = '頁面無法載入';
              downList = false;
            "
            :class="{ active: type == '頁面無法載入' }"
          >
            頁面無法載入
          </div>
          <div
            @click.stop="
              type = '星盤資料相關';
              downList = false;
            "
            :class="{ active: type == '星盤資料相關' }"
          >
            星盤資料相關
          </div>
          <div
            @click.stop="
              type = '資料錯置';
              downList = false;
            "
            :class="{ active: type == '資料錯置' }"
          >
            資料錯置
          </div>
          <div
            @click.stop="
              type = '修改資料失敗';
              downList = false;
            "
            :class="{ active: type == '修改資料失敗' }"
          >
            修改資料失敗
          </div>
          <div
            @click.stop="
              type = '其他';
              downList = false;
            "
            :class="{ active: type == '其他' }"
          >
            其他
          </div>
        </div>
      </div>
      <!-- start 上傳截圖區 -->
      <p class="form_title">上傳截圖({{ imgLength }}/4)</p>
      <div class="screenshot">
        <div class="screenshot_item_container" v-for="item in 4" :key="item">
          <label
            class="screenshot_item"
            :for="`screenshot${item}`"
            :class="{ active: imgArray[`UpdateImg${item}`] }"
          >
            <div class="screenshot_inner"></div>
            <div
              class="updateIMG"
              v-if="imgArray[`UpdateImg${item}`]"
              :class="{ active: imgArray[`UpdateImg${item}`] }"
            >
              <img :src="imgArray[`UpdateImg${item}`]" />
            </div>
            <!-- <input v-else type="file" :id="`screenshot${item}`" accept="image/gif, image/jpeg, image/png" @change="updateMethods[`${item}`]($event)">  -->

            <div
              class="delete"
              @click.prevent="imgArray[`UpdateImg${item}`] = null"
              v-if="imgArray[`UpdateImg${item}`]"
            >
              <img src="../../assets/report/close.svg" alt="" />
            </div>
          </label>
          <img
            class="screenshot_inner_img"
            @click="toFocus(item)"
            src="../../assets/report/icon_add.svg"
          />
        </div>
      </div>
      <p class="screenshot_notice">
        提供完整資訊的問題截圖，能讓我們更快改善問題
      </p>
      <!-- end 上傳截圖區 -->
      <p class="form_title">詳細內容(選填)</p>
      <textarea
        class="detail"
        cols="30"
        v-model="detail"
        placeholder="能進一步告訴我們詳情嗎？"
      ></textarea>
      <p class="form_notice" :class="{ OK: detail, OKK: detail.length > 100 }">
        完整的細節能讓我們更快釐清唷
      </p>
      <div class="btn_group">
        <div class="btn pre" @click="step1(0)">上一步</div>
        <div
          class="btn next"
          @click="step1(1)"
          :class="{ active: imgLength > 0 }"
        >
          下一步
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      loginMethod: this.data.loginMethod,
      type: this.data.type,
      detail: this.data.detail,
      downList: false,
      imgArray: {
        UpdateImg1: this.data.imgArray.UpdateImg1,
        UpdateImg2: this.data.imgArray.UpdateImg2,
        UpdateImg3: this.data.imgArray.UpdateImg3,
        UpdateImg4: this.data.imgArray.UpdateImg4,
      },
      updateMethods: {
        1: this.updateFile_1,
        2: this.updateFile_2,
        3: this.updateFile_3,
        4: this.updateFile_4,
      },
      // imgValue:{
      //     imgValue1:this.data.imgValue.imgValue1,
      //     imgValue2:this.data.imgValue.imgValue2,
      //     imgValue3:this.data.imgValue.imgValue3,
      //     imgValue4:this.data.imgValue.imgValue4
      // }
    };
  },
  methods: {
    step1(next) {
      if (next) {
        if (this.imgLength < 1) {
          return alert("請至少付上一張截圖喔!");
        }
      }
      let data = {
        loginMethod: this.loginMethod,
        type: this.type,
        detail: this.detail,
        imgArray: this.imgArray,
        // imgValue:this.imgValue,
        next: next,
      };
      this.$emit("step1", data);
    },
    updateFile_1(e) {
      const fileValue = e.target.files.item(0);
      // this.imgValue.imgValue1 = fileValue
      this.updateFile(e, "UpdateImg1");
    },
    updateFile_2(e) {
      const fileValue = e.target.files.item(0);
      // this.imgValue.imgValue2 = fileValue
      this.updateFile(e, "UpdateImg2");
    },
    updateFile_3(e) {
      const fileValue = e.target.files.item(0);
      // this.imgValue.imgValue3 = fileValue
      this.updateFile(e, "UpdateImg3");
    },
    updateFile_4(e) {
      const fileValue = e.target.files.item(0);
      // this.imgValue.imgValue4 = fileValue
      this.updateFile(e, "UpdateImg4");
    },
    //共用上傳函數
    updateFile(e, target) {
      const file = e.target.files.item(0);
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.imgArray[target] = event.target.result;
      });
      reader.readAsDataURL(file);
    },
    //上傳框框中那個十字按紐
    toFocus(item) {
      this.$emit("toFocus", item);
    },
  },
  computed: {
    //上傳數量
    imgLength() {
      let length = 0;
      for (var item in this.imgArray) {
        if (this.imgArray[item]) {
          length++;
        }
      }
      return length;
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report.scss";
.step1 {
  user-select: none;
  .main_title {
    margin-top: 32px;
    @include pad {
      margin-top: 4px;
    }
  }
  hr {
    margin: 22px 0 0;
  }
}
.form {
  display: flex;
  flex-direction: column;
  padding: 0 28px;

  @include pad {
    padding: 0;
  }
  .form_title {
    width: 100%;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.96px;
    color: #868686;
    margin-bottom: 4px;
    margin-top: 16px;
  }
  .loginMethod {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    box-shadow: 4px 4px 9px 1px rgba(0, 0, 0, 0.2), -2px -2px 2px 2px #ffffff,
      -2px -2px 4px 0 #ffffff;
    background-color: #f0f2f4;
    padding: 6px 4px;
    display: flex;
    > div {
      margin: 0 4px;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.44;
      letter-spacing: 1.08px;
      flex-grow: 1;
      text-align: center;
      color: #868686;
      cursor: pointer;
      &.active {
        border-radius: 4px;
        box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15),
          2px 2px 4px 0 #ffffff;
        background-color: #f0f2f4;
        color: #17445c;
      }
    }
  }
  .type {
    width: 240px;
    border-radius: 15px;
    box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
    padding: 4px 1em 4px 1em;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1.08px;
    color: #17445c;
    position: relative;
    cursor: pointer;
    background: url("../../assets/report/selectIcon_down.png") no-repeat right
      center #f0f2f4;
    .content {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 240px;
      border-radius: 15px;
      color: #868686;
      box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
      background: url("../../assets/report/selectIcon_up.png") no-repeat right
        top #f0f2f4;
      overflow: hidden;
      div {
        padding: 4px 1em 4px 1em;
        cursor: pointer;
        &:hover {
          background-color: rgba(175, 175, 175, 0.3);
        }
        &.active {
          color: #17445c;
        }
      }
    }
  }
  .screenshot {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .screenshot_item_container {
    width: 88px;
    height: 80px;
    position: relative;
    @include pad {
      width: 72px;
      height: 72px;
    }
  }
  .screenshot_item {
    width: 100%;
    height: 100%;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    border-radius: 8px;
    box-shadow: -2px -2px 1px 1px #ffffff,
      -2px -2px 3px 0px rgba(255, 255, 255, 0.25),
      0 4px 4px 0px rgba(0, 0, 0, 0.25);
    // box-shadow: 4px 4px 9px 1px rgba(0, 0, 0, 0.2), -2px -2px 2px 2px #ffffff, -2px -2px 4px 0 #ffffff;
    border: solid 2px #f0f2f4b7;
    cursor: pointer;
    position: relative;
    @include center;
    // @include pad{
    //     width: 72px;
    //     height: 72px;
    // }
    .delete {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #979797da;
      position: absolute;
      right: -8px;
      top: -8px;
      cursor: pointer;
      @include center;
      @include pad {
        width: 20px;
        height: 20px;
      }
      img {
        width: 90%;
        height: 90%;
      }
    }
    //上傳截圖後
    &.active {
      box-shadow: none;
      cursor: auto;
      -webkit-filter: blur(0px);
      filter: blur(0px);
    }
    .screenshot_inner {
      width: 80px;
      height: 72px;
      border-radius: 6px;
      box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
      @include center;
      @include pad {
        width: 64px;
        height: 64px;
      }
    }

    //上傳截圖
    .updateIMG {
      position: absolute;
      top: 0;
      left: 0;
      width: 86px;
      height: 78px;
      border: solid 1px #d9d9d9;
      border-radius: 8px;
      overflow: hidden;
      @include pad {
        width: 72px;
        height: 72px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .screenshot_inner_img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.screenshot_notice {
  padding: 0;
  margin-top: 8px;
  opacity: 0.2;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.96px;
  color: #292929;
}
.detail {
  width: 100%;
  height: 104px;
  border-radius: 8px;
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
  background-color: #f0f2f4;
  line-height: 1.44;
  letter-spacing: 1.08px;
  color: #17445c;
  padding: 8px 1em 8px 1em;
  resize: none;
  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
    transform: translateX(-2px);
    // background: #00f;
  }

  &::-webkit-scrollbar-thumb {
    background: #868686;
  }

  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: #2929293a;
  }
}
.form_notice {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.48px;
  color: #29292941;
  margin-bottom: 4px;
  margin-top: 16px;
  text-align: right;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    bottom: -6px;
    height: 2px;
    width: 15%;
    border-radius: 2px;
    background-color: #d9d9d9;
  }
  &:before {
    right: 15%;
  }
  &:after {
    right: 0;
  }
  &.OK {
    &:before {
      background-color: #798da5;
    }
  }
  &.OKK {
    &:after {
      background-color: #798da5;
    }
  }
}
</style>
