var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "step0 main_container" },
      [
        _c("h3", { staticClass: "main_title hidden-sm-and-down" }, [
          _vm._v("進入管道")
        ]),
        _c("p", { staticClass: "main_description hidden-sm-and-down" }, [
          _vm._v("請選擇您進入官網的管道")
        ]),
        _c("h3", { staticClass: "main_title hidden-md-and-up" }, [
          _vm._v("問題回報")
        ]),
        _vm._t("default"),
        _c("hr", { staticClass: "mt-8" }),
        _c("div", { staticClass: "enter_group" }, [
          _c("h3", { staticClass: "enter_title hidden-md-and-up" }, [
            _vm._v("進入管道")
          ]),
          _c("p", { staticClass: "enter_description hidden-md-and-up" }, [
            _vm._v("請選擇您進入星盤的管道")
          ]),
          _c(
            "div",
            {
              staticClass: "enter_item",
              on: {
                click: function($event) {
                  return _vm.next("Line內建瀏覽器")
                }
              }
            },
            [_vm._m(0), _vm._m(1)]
          ),
          _c(
            "div",
            {
              staticClass: "enter_item",
              on: {
                click: function($event) {
                  return _vm.next("一般web瀏覽器")
                }
              }
            },
            [_vm._m(2), _vm._m(3)]
          )
        ])
      ],
      2
    ),
    _c("p", { staticClass: "notice" }, [
      _vm._v(
        "\n    為了保護您的權益，您所填寫的資料僅在必要時提供唐綺陽星盤工程團隊使用。\n  "
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "enter_logo" }, [
      _c("img", {
        attrs: { src: require("../../assets/report/icon_line.svg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "enter_content" }, [
      _c("p", { staticClass: "enter_title" }, [_vm._v("Line")]),
      _c("p", { staticClass: "enter_description" }, [
        _vm._v("從LINE選單進入星盤官網")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "enter_logo" }, [
      _c("img", { attrs: { src: require("../../assets/report/icon_web.svg") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "enter_content" }, [
      _c("p", { staticClass: "enter_title" }, [_vm._v("一般瀏覽器")]),
      _c("p", { staticClass: "enter_description" }, [
        _vm._v("從一般瀏覽器進入星盤官網")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }