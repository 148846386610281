<template>
  <div class="stepper">
    <div class="wrap">
      <div class="item">
        <div class="external"></div>
        <div class="inner" :class="{ OK: step > 0, OKK: step > 1 }"></div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="external"></div>
        <div class="inner" :class="{ OK: step > 1, OKK: step > 2 }"></div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="external"></div>
        <div class="inner" :class="{ OK: step > 2 }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
.stepper {
  width: 100%;
  @include pad {
    margin: 24px 0 16px;
  }
}
.wrap {
  width: 252px;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pad {
    width: 210px;
    margin: 0 auto;
  }
  .item {
    width: 20px;
    height: 20px;
    position: relative;
    @include pad {
      width: 18px;
      height: 18px;
    }
    .external {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #f0f2f4;
      box-shadow: -2px -2px 3px 0 #fff, -2px -2px 1px 0 #fff,
        2px 2px 1px 0 rgba(0, 0, 0, 0.3);
      filter: blur(1.5px);
    }
    .inner {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d9d9d9;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include pad {
        width: 8px;
        height: 8px;
      }
      &.OK {
        background-color: #868686;
      }
      &.OKK {
        background-color: #6bc66f !important;
        box-shadow: 0 0 8px 0 #6bc66f !important;
      }
    }
  }
  .line {
    width: 64px;
    height: 2px;
    background-color: #d9d9d9;
    @include pad {
      width: 48px;
    }
  }
}
</style>
