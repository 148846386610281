var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step3 main_container" },
    [
      _c("h3", { staticClass: "main_title" }, [_vm._v("聯絡資料")]),
      _c("p", { staticClass: "main_description" }, [
        _vm._v("提供詳細的資訊能幫助我們改善服務哦！")
      ]),
      _vm._t("default"),
      _c("hr"),
      _c("div", { staticClass: "form" }, [
        _c("p", { staticClass: "form_title" }, [_vm._v("用戶名稱")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.contact_name,
              expression: "contact_name"
            }
          ],
          staticClass: "form_name",
          attrs: { type: "text", placeholder: "姓名" },
          domProps: { value: _vm.contact_name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.contact_name = $event.target.value
            }
          }
        }),
        _c("p", { staticClass: "form_title" }, [_vm._v("電子信箱")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.contact_email,
              expression: "contact_email"
            }
          ],
          staticClass: "form_email",
          attrs: { type: "email", placeholder: "Yourname@gmail.com" },
          domProps: { value: _vm.contact_email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.contact_email = $event.target.value
            }
          }
        }),
        _c("div", { staticClass: "space" }),
        _c("p", { staticClass: "notice" }, [
          _vm._v(
            "\n      傳送即表示您已同意隱私權及服務條款，為了保護您的權益，我們只在必要時提供唐綺陽星盤工程團隊使用。\n    "
          )
        ]),
        _c("div", { staticClass: "btn_group" }, [
          _c(
            "div",
            {
              staticClass: "btn pre",
              on: {
                click: function($event) {
                  return _vm.step3(false)
                }
              }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "div",
            {
              directives: [
                { name: "preventRepeat", rawName: "v-preventRepeat" }
              ],
              staticClass: "btn next",
              class: { active: _vm.contact_name && _vm.contact_email },
              on: {
                click: function($event) {
                  return _vm.step3(true)
                }
              }
            },
            [_vm._v("\n        傳送\n      ")]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }