var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "report_container" }, [
        _c(
          "div",
          { staticClass: "main" },
          [
            _vm.step == 0
              ? _c(
                  "Step0",
                  {
                    on: {
                      step0: function($event) {
                        return _vm.step0($event)
                      }
                    }
                  },
                  [
                    _c("Stepper", {
                      staticClass: "hidden-md-and-up",
                      attrs: { step: _vm.step }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.step == 1
              ? _c(
                  "Step1",
                  {
                    ref: "userdata",
                    attrs: { data: _vm.step1_Data },
                    on: {
                      step1: function($event) {
                        return _vm.step1($event)
                      },
                      toFocus: function($event) {
                        return _vm.toFocus($event)
                      }
                    }
                  },
                  [
                    _c("Stepper", {
                      staticClass: "hidden-md-and-up",
                      attrs: { step: _vm.step }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(4, function(item) {
              return _c("input", {
                key: item,
                ref: "file" + item,
                refInFor: true,
                attrs: {
                  accept: "image/gif, image/jpeg, image/png",
                  type: "file",
                  id: "screenshot" + item
                },
                on: {
                  change: function($event) {
                    _vm.$refs.userdata.updateMethods["" + item]($event)
                  }
                }
              })
            }),
            _vm.step == 2
              ? _c(
                  "Step2",
                  {
                    ref: "user",
                    attrs: { data: _vm.step2_Data, DontKnow: _vm.DontKnow },
                    on: {
                      step2: function($event) {
                        return _vm.step2($event)
                      }
                    }
                  },
                  [
                    _c("Stepper", {
                      staticClass: "hidden-md-and-up",
                      attrs: { step: _vm.step }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.step == 3
              ? _c(
                  "Step3",
                  {
                    ref: "contact",
                    attrs: { data: _vm.step3_Data },
                    on: {
                      step3: function($event) {
                        return _vm.step3($event)
                      }
                    }
                  },
                  [
                    _c("Stepper", {
                      staticClass: "hidden-md-and-up",
                      attrs: { step: _vm.step }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "side" },
          [
            _c("img", { attrs: { src: require("../assets/report/side.png") } }),
            _c("Stepper", {
              staticClass: "side_stepper",
              attrs: { step: _vm.step }
            })
          ],
          1
        )
      ]),
      _vm.openSendEmailResult
        ? _c("SendEmailResult", {
            attrs: { text: "謝謝您的回報，我們將盡快為您優化", direction: "/" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header hidden-sm-and-down" }, [
      _c("img", {
        attrs: { src: require("../assets/report/icon_report.svg") }
      }),
      _c("h2", [_vm._v("問題回報")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }