<template>
  <div class="step3 main_container">
    <h3 class="main_title">聯絡資料</h3>
    <p class="main_description">提供詳細的資訊能幫助我們改善服務哦！</p>
    <slot></slot>
    <hr />
    <div class="form">
      <p class="form_title">用戶名稱</p>
      <input
        class="form_name"
        type="text"
        placeholder="姓名"
        v-model="contact_name"
      />
      <p class="form_title">電子信箱</p>
      <input
        class="form_email"
        type="email"
        placeholder="Yourname@gmail.com"
        v-model="contact_email"
      />
      <!-- RWD間距 -->
      <div class="space"></div>
      <p class="notice">
        傳送即表示您已同意隱私權及服務條款，為了保護您的權益，我們只在必要時提供唐綺陽星盤工程團隊使用。
      </p>
      <div class="btn_group">
        <div class="btn pre" @click="step3(false)">上一步</div>
        <div
          class="btn next"
          @click="step3(true)"
          v-preventRepeat
          :class="{ active: contact_name && contact_email }"
        >
          傳送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      contact_name: this.data.contact_name,
      contact_email: this.data.contact_email,
    };
  },
  methods: {
    step3(next) {
      if (next) {
        if (!this.contact_name || !this.contact_email) {
          return alert("有欄位還沒填寫喔!");
        }
      }
      let data = {
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        next: next,
      };
      this.$emit("step3", data);
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report.scss";
.step3 {
  user-select: none;
  .main_title {
    margin-top: 32px;
    @include pad {
      margin-top: 4px;
    }
  }
  hr {
    margin: 22px 0 0;
  }
}
.form {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  padding: 0 28px;
  @include pad {
    padding: 0;
  }
  .form_title {
    width: 100%;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.96px;
    color: #868686;
    margin-bottom: 4px;
    margin-top: 16px;
  }
  .form_name,
  .form_email {
    height: 40px;
    border-radius: 20px;
    box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
    background-color: #f0f2f4;
    padding: 4px 1em 4px 1em;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1.08px;
    color: #17445c;
    &:focus {
      outline: none;
      border: none;
    }
    &::placeholder {
      color: #2929293a;
    }
  }
  .form_name {
    width: 240px;
  }
  .form_email {
    width: 100%;
  }
  .space {
    height: 188px;
    @include pad {
      height: 60px;
    }
  }
  .notice {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.48px;
    color: #29292946;
    span {
      text-decoration: underline;
      color: #ab956c;
    }
    @include pad {
      text-align: center;
    }
  }
}
</style>
