var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step2 main_container" },
    [
      _c("h3", { staticClass: "main_title" }, [_vm._v("星盤相關資料")]),
      _c("p", { staticClass: "main_description" }, [
        _vm._v("我們將會依照您提供的資訊，盡快解決相關的問題")
      ]),
      _vm._t("default"),
      _c("hr"),
      _c("div", { staticClass: "personal__info" }, [
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("姓名")]),
          _c("div", { staticClass: "item__input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              attrs: { type: "text", placeholder: "請輸入姓名" },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            }),
            !_vm.nameCheck
              ? _c("img", {
                  staticClass: "input__error",
                  attrs: { src: require("@/assets/icon_wrong.svg"), alt: "" }
                })
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生日期")]),
          _c(
            "div",
            {
              staticClass: "item__input",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.dateModal = true
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.date))]),
              _c(
                "v-dialog",
                {
                  ref: "dialog",
                  attrs: {
                    "return-value": _vm.date,
                    width: "290px",
                    persistent: ""
                  },
                  on: {
                    "update:returnValue": function($event) {
                      _vm.date = $event
                    },
                    "update:return-value": function($event) {
                      _vm.date = $event
                    }
                  },
                  model: {
                    value: _vm.dateModal,
                    callback: function($$v) {
                      _vm.dateModal = $$v
                    },
                    expression: "dateModal"
                  }
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      staticClass: "picker date-picker",
                      attrs: {
                        "first-day-of-week": 0,
                        locale: "zh-cn",
                        scrollable: "",
                        "header-color": "rgba(217, 217, 217, 0.25)",
                        color: "primary",
                        max: "2099-12-31",
                        min: "0676-01-01"
                      },
                      model: {
                        value: _vm.date,
                        callback: function($$v) {
                          _vm.date = $$v
                        },
                        expression: "date"
                      }
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function($event) {
                              _vm.dateModal = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function($event) {
                              _vm.$refs.dialog.save(_vm.date)
                              _vm.dateModal = false
                            }
                          }
                        },
                        [_vm._v("確認")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生時間")]),
          _c("div", { staticClass: "time-wrap" }, [
            _c(
              "div",
              {
                staticClass: "item__input",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.timeModal = true
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.time))]),
                _c(
                  "v-dialog",
                  {
                    ref: "dialog2",
                    attrs: {
                      "return-value": _vm.time,
                      width: "290px",
                      persistent: ""
                    },
                    on: {
                      "update:returnValue": function($event) {
                        _vm.time = $event
                      },
                      "update:return-value": function($event) {
                        _vm.time = $event
                      }
                    },
                    model: {
                      value: _vm.timeModal,
                      callback: function($$v) {
                        _vm.timeModal = $$v
                      },
                      expression: "timeModal"
                    }
                  },
                  [
                    _vm.timeModal
                      ? _c(
                          "v-time-picker",
                          {
                            staticClass: "picker time-picker",
                            attrs: {
                              scrollable: "",
                              "ampm-in-title": "",
                              "header-color": "rgba(217, 217, 217, 0.25)",
                              color: "primary"
                            },
                            model: {
                              value: _vm.time,
                              callback: function($$v) {
                                _vm.time = $$v
                              },
                              expression: "time"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    _vm.timeModal = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    _vm.$refs.dialog2.save(_vm.time)
                                    _vm.timeModal = false
                                  }
                                }
                              },
                              [_vm._v("確認")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("label", { staticClass: "checkbox-wrap" }, [
              _vm._v("\n          我不知道\n          "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.IDontKnow,
                    expression: "IDontKnow"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.IDontKnow)
                    ? _vm._i(_vm.IDontKnow, null) > -1
                    : _vm.IDontKnow
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.IDontKnow,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.IDontKnow = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.IDontKnow = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.IDontKnow = $$c
                    }
                  }
                }
              }),
              _c("span", { staticClass: "checkmark" })
            ])
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _vm._m(0),
          _c("div", { staticClass: "item__sex" }, [
            _c(
              "div",
              {
                staticClass: "sex-box male",
                class: { activeSex: _vm.Daylight },
                on: {
                  click: function($event) {
                    _vm.isDaylight()
                    _vm.DaylightNotice = true
                  }
                }
              },
              [
                _vm._v("\n          是\n          "),
                _vm.DaylightNotice
                  ? _c("div", { staticClass: "daylight" }, [
                      _vm._v("\n            系統將自動"),
                      _c("br"),
                      _vm._v("\n            以日光節約時間測算\n          ")
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "sex-box female",
                class: { activeSex: !_vm.Daylight },
                on: {
                  click: function($event) {
                    _vm.notDaylight()
                    _vm.DaylightNotice = false
                  }
                }
              },
              [_vm._v("\n          否\n        ")]
            )
          ])
        ]),
        _c("div", { staticClass: "personal__item Place" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生地點")]),
          _c(
            "div",
            { staticClass: "item__input" },
            [
              _c("v-select", {
                staticClass: "select",
                attrs: {
                  items: _vm.locationItems,
                  "background-color": "transparent",
                  solo: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.location,
                  callback: function($$v) {
                    _vm.location = $$v
                  },
                  expression: "location"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "personal__item OtherPlace",
            class: { active: _vm.showOtherPlace }
          },
          [
            _c("span", { staticClass: "item__title otherPlace" }, [
              _vm._v("出生國家")
            ]),
            _c("div", { staticClass: "item__input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.Country,
                    expression: "Country"
                  }
                ],
                attrs: {
                  type: "text",
                  value: "",
                  placeholder: "請輸入國家，例如美國"
                },
                domProps: { value: _vm.Country },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.Country = $event.target.value
                  }
                }
              }),
              !_vm.countryCheck
                ? _c("img", {
                    staticClass: "input__error",
                    attrs: { src: require("@/assets/icon_wrong.svg"), alt: "" }
                  })
                : _vm._e()
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "personal__item OtherPlace",
            class: { active: _vm.showOtherPlace }
          },
          [
            _c("span", { staticClass: "item__title otherPlace" }, [
              _vm._v("出生州省")
            ]),
            _c("div", { staticClass: "item__input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.State,
                    expression: "State"
                  }
                ],
                attrs: {
                  type: "text",
                  value: "",
                  placeholder: "請輸入州或省，例如紐約州"
                },
                domProps: { value: _vm.State },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.State = $event.target.value
                  }
                }
              })
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "personal__item OtherPlace",
            class: { active: _vm.showOtherPlace }
          },
          [
            _c("span", { staticClass: "item__title otherPlace" }, [
              _vm._v("出生城市")
            ]),
            _c("div", { staticClass: "item__input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.City,
                    expression: "City"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "請輸入城市名，例如水牛城"
                },
                domProps: { value: _vm.City },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.City = $event.target.value
                  }
                }
              }),
              !_vm.cityCheck
                ? _c("img", {
                    staticClass: "input__error",
                    attrs: { src: require("@/assets/icon_wrong.svg"), alt: "" }
                  })
                : _vm._e()
            ])
          ]
        ),
        _c("div", { staticClass: "btn_group" }, [
          _c(
            "div",
            {
              staticClass: "btn pre",
              on: {
                click: function($event) {
                  return _vm.step2(false)
                }
              }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "div",
            {
              staticClass: "btn next",
              class: { active: _vm.canGo },
              on: {
                click: function($event) {
                  return _vm.step2(true)
                }
              }
            },
            [_vm._v("\n        下一步\n      ")]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "item__title" }, [
      _vm._v("日光節約時間\n        "),
      _c("img", { attrs: { src: require("@/assets/日光節約icon.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }