<template>
  <div class="step2 main_container">
    <h3 class="main_title">星盤相關資料</h3>
    <p class="main_description">我們將會依照您提供的資訊，盡快解決相關的問題</p>
    <slot></slot>
    <hr />
    <div class="personal__info">
      <div class="personal__item">
        <span class="item__title">姓名</span>
        <div class="item__input">
          <input type="text" v-model="name" placeholder="請輸入姓名" />
          <img
            class="input__error"
            v-if="!nameCheck"
            src="@/assets/icon_wrong.svg"
            alt
          />
        </div>
      </div>
      <div class="personal__item">
        <span class="item__title">出生日期</span>

        <div class="item__input" @click.stop="dateModal = true">
          <span>{{ date }}</span>
          <v-dialog
            ref="dialog"
            v-model="dateModal"
            :return-value.sync="date"
            width="290px"
            persistent
          >
            <v-date-picker
              class="picker date-picker"
              v-model="date"
              :first-day-of-week="0"
              locale="zh-cn"
              scrollable
              header-color="rgba(217, 217, 217, 0.25)"
              color="primary"
              max="2099-12-31"
              min="0676-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="dateModal = false">取消</v-btn>
              <v-btn
                text
                color="error"
                @click="
                  $refs.dialog.save(date);
                  dateModal = false;
                "
                >確認</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </div>
      </div>
      <div class="personal__item">
        <span class="item__title">出生時間</span>
        <!-- - -->
        <div class="time-wrap">
          <div class="item__input" @click.stop="timeModal = true">
            <span>{{ time }}</span>
            <v-dialog
              ref="dialog2"
              v-model="timeModal"
              :return-value.sync="time"
              width="290px"
              persistent
            >
              <v-time-picker
                class="picker time-picker"
                v-model="time"
                v-if="timeModal"
                scrollable
                ampm-in-title
                header-color="rgba(217, 217, 217, 0.25)"
                color="primary"
              >
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="timeModal = false"
                  >取消</v-btn
                >
                <v-btn
                  text
                  color="error"
                  @click="
                    $refs.dialog2.save(time);
                    timeModal = false;
                  "
                  >確認</v-btn
                >
              </v-time-picker>
            </v-dialog>
          </div>
          <label class="checkbox-wrap">
            我不知道
            <input type="checkbox" v-model="IDontKnow" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <!-- 日光節約時間。 -->
      <div class="personal__item">
        <span class="item__title"
          >日光節約時間
          <img src="@/assets/日光節約icon.svg" />
        </span>
        <div class="item__sex">
          <div
            class="sex-box male"
            :class="{ activeSex: Daylight }"
            @click="
              isDaylight();
              DaylightNotice = true;
            "
          >
            是
            <div class="daylight" v-if="DaylightNotice">
              系統將自動<br />
              以日光節約時間測算
            </div>
          </div>
          <div
            class="sex-box female"
            :class="{ activeSex: !Daylight }"
            @click="
              notDaylight();
              DaylightNotice = false;
            "
          >
            否
          </div>
        </div>
      </div>
      <div class="personal__item Place">
        <span class="item__title">出生地點</span>

        <div class="item__input">
          <v-select
            class="select"
            :items="locationItems"
            v-model="location"
            background-color="transparent"
            solo
            hide-details
          ></v-select>
        </div>
      </div>

      <!-- 新增其他地點 -->
      <div
        class="personal__item OtherPlace"
        :class="{ active: showOtherPlace }"
      >
        <span class="item__title otherPlace">出生國家</span>
        <div class="item__input">
          <input
            type="text"
            v-model="Country"
            value
            placeholder="請輸入國家，例如美國"
          />
          <img
            class="input__error"
            v-if="!countryCheck"
            src="@/assets/icon_wrong.svg"
            alt
          />
        </div>
      </div>
      <div
        class="personal__item OtherPlace"
        :class="{ active: showOtherPlace }"
      >
        <span class="item__title otherPlace">出生州省</span>
        <div class="item__input">
          <input
            type="text"
            v-model="State"
            value
            placeholder="請輸入州或省，例如紐約州"
          />
        </div>
      </div>
      <div
        class="personal__item OtherPlace"
        :class="{ active: showOtherPlace }"
      >
        <span class="item__title otherPlace">出生城市</span>
        <div class="item__input">
          <input
            type="text"
            v-model="City"
            placeholder="請輸入城市名，例如水牛城"
          />
          <img
            class="input__error"
            v-if="!cityCheck"
            src="@/assets/icon_wrong.svg"
            alt
          />
        </div>
      </div>
      <!-- 新增其他地點end -->

      <!-- 按鈕 -->
      <div class="btn_group">
        <div class="btn pre" @click="step2(false)">上一步</div>
        <div class="btn next" @click="step2(true)" :class="{ active: canGo }">
          下一步
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    DontKnow: Boolean,
  },
  data() {
    return {
      date: "1990-05-30",
      dateModal: false,
      time: "12:00",
      timeModal: false,
      ID: null,
      name: null,
      location: "台北",
      locationItems: [
        "基隆",
        "台北",
        "新北",
        "桃園",
        "新竹",
        "苗栗",
        "台中",
        "彰化",
        "南投",
        "雲林",
        "嘉義",
        "台南",
        "高雄",
        "屏東",
        "台東",
        "花蓮",
        "宜蘭",
        "澎湖",
        "金門",
        "馬祖",
        "香港",
        "澳門",
        "其他",
      ],
      checklist: [
        { text: "基隆", value: "Keelung" },
        { text: "台北", value: "Taipei" },
        { text: "新北", value: "New Taipei" },
        { text: "桃園", value: "Taoyuan" },
        { text: "新竹", value: "Hsinchu" },
        { text: "苗栗", value: "Miaoli" },
        { text: "台中", value: "Taichung" },
        { text: "彰化", value: "Changhua" },
        { text: "南投", value: "Nantou" },
        { text: "雲林", value: "Yunlin" },
        { text: "嘉義", value: "Chiayi" },
        { text: "台南", value: "Tainan" },
        { text: "高雄", value: "Kaohsiung" },
        { text: "屏東", value: "Pingtung" },
        { text: "台東", value: "Taitung" },
        { text: "花蓮", value: "Hualien" },
        { text: "宜蘭", value: "Yilan" },
        { text: "澎湖", value: "Penghu" },
        { text: "金門", value: "Kinmen" },
        { text: "馬祖", value: "Mazu" },
        { text: "香港", value: "Hongkong" },
        { text: "澳門", value: "Macao" },
        { text: "其他", value: "Other" },
      ],
      IDontKnow: this.DontKnow,
      nameCheck: true,
      countryCheck: true,
      cityCheck: true,
      fileNotApply: false,
      Country: null,
      City: null,
      State: null,
      showOtherPlace: false,
      passLocation: "Taipei",
      Gender: "M",
      Icon: require("@/assets/cat.png"),
      canClick: true,
      Daylight: false,
      DaylightNotice: false,
    };
  },
  methods: {
    step2(next) {
      if (next) {
        if (!this.check()) {
          return;
        }
      }
      let data = {
        Name: this.name,
        BirthCountry: this.Country,
        BirthState: this.State ? this.State : null,
        BirthCity: this.City,
        City: this.location,
        BirthDay: this.date,
        BirthTime: this.time,
        IDontKnow: this.IDontKnow,
        isDaylight: this.Daylight,
        next: next,
      };
      this.$emit("step2", data);
    },
    //處理data顯示，6/5新增
    showData(Mydata) {
      this.location = Mydata.City;
      this.ID = Mydata.AstrolabeFileId;
      if (this.location === "其他") {
        this.showOtherPlace = true;
      }
      this.checklist.forEach((item, index) => {
        if (item.text === this.location) {
          this.passLocation = item.value;
        }
      });
      this.Country = Mydata.BirthCountry;
      this.State = Mydata.BirthState;
      this.City = Mydata.BirthCity;
      this.name = Mydata.Name;
      this.Icon = Mydata.ProfilePhotoSrc;
      this.time = Mydata.BirthTime;
      this.Daylight = Mydata.IsDaylight;
      let BD = Mydata.BirthDay;
      let arr = BD.split("");
      arr[4] = "-";
      arr[7] = "-";
      BD = arr.join("");
      this.date = BD;
    },
    cancel() {
      window.localStorage.removeItem("editMe");
      this.$router.push({ path: "/member" });
    },
    isDaylight() {
      this.Daylight = true;
    },
    notDaylight() {
      this.Daylight = false;
    },
    check() {
      if (!this.name) {
        alert("請填寫星盤名稱");
        return false;
      }

      if (this.location == "其他") {
        if (!this.Country) {
          alert("請填寫出生國家");
          return false;
        }
        if (!this.City) {
          alert("請填寫出生城市");
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    if (this.data) {
      this.showData(this.data);
    }
    this.$vuetify.goTo(0);
  },
  computed: {
    canGo() {
      if (!this.name) {
        return false;
      }

      if (this.location == "其他") {
        if (!this.Country) {
          return false;
        }
        if (!this.City) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    location: function (value) {
      this.checklist.forEach((item) => {
        if (item.text === value) {
          this.passLocation = item.value;
        }
      });
      if (value === "其他") {
        this.showOtherPlace = true;
        this.fileNotApply = true;
      } else {
        this.showOtherPlace = false;
        if (this.nameCheck) {
          this.fileNotApply = false;
        }
        this.countryCheck = true;
        this.cityCheck = true;
        this.Country = null;
        this.City = null;
      }
    },
    Country: function (value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.countryCheck = false;
          this.fileNotApply = true;
        } else {
          this.countryCheck = true;
          if (this.nameCheck && this.cityCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    City: function (value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.cityCheck = false;
          this.fileNotApply = true;
        } else {
          this.cityCheck = true;
          if (this.nameCheck && this.countryCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    name: function (value) {
      if (value === null || value === "") {
        this.nameCheck = false;
        this.fileNotApply = true;
      } else {
        this.nameCheck = true;
        if (this.countryCheck && this.cityCheck) {
          this.fileNotApply = false;
        }
      }
    },
    IDontKnow: function (value) {
      if (value === true) {
        this.time = "12:00";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report.scss";
@import "@/scss/userFile.scss";
@import "@/scss/editFile.scss";
.step2 {
  .main_title {
    margin-top: 32px;
    @include pad {
      margin-top: 4px;
    }
  }
  hr {
    margin: 22px 0 0;
  }
  .personal__info {
    margin-top: 0;
    padding: 20px 24px 28px;
  }
  .personal__item {
    margin-bottom: 28px;
    &.Place {
      margin-bottom: 20px;
    }
    &.OtherPlace {
      margin-bottom: 16px;
      visibility: hidden;
      @include pad {
        display: none;
        margin-bottom: 28px;
      }
      &.active {
        visibility: visible;
        @include pad {
          display: flex;
        }
      }
    }
  }
}
</style>
