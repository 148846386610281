var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step1 main_container" },
    [
      _c("h3", { staticClass: "main_title" }, [_vm._v("問題描述")]),
      _c("p", { staticClass: "main_description" }, [
        _vm._v("提供詳細的資訊能幫助我們改善服務哦！")
      ]),
      _vm._t("default"),
      _c("hr"),
      _c("div", { staticClass: "form" }, [
        _c("p", { staticClass: "form_title" }, [_vm._v("登入方式")]),
        _c("div", { staticClass: "loginMethod" }, [
          _c(
            "div",
            {
              class: { active: _vm.loginMethod == "Line" },
              on: {
                click: function($event) {
                  _vm.loginMethod = "Line"
                }
              }
            },
            [_vm._v("\n        LINE\n      ")]
          ),
          _c(
            "div",
            {
              class: { active: _vm.loginMethod == "Facebook" },
              on: {
                click: function($event) {
                  _vm.loginMethod = "Facebook"
                }
              }
            },
            [_vm._v("\n        Facebook\n      ")]
          ),
          _c(
            "div",
            {
              class: { active: _vm.loginMethod == "Google" },
              on: {
                click: function($event) {
                  _vm.loginMethod = "Google"
                }
              }
            },
            [_vm._v("\n        Google\n      ")]
          )
        ]),
        _c("p", { staticClass: "form_title" }, [_vm._v("問題類型")]),
        _c(
          "div",
          {
            staticClass: "type",
            on: {
              click: function($event) {
                _vm.downList = true
              }
            }
          },
          [
            _vm._v("\n      " + _vm._s(_vm.type) + "\n      "),
            _vm.downList
              ? _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "無法登入" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "無法登入"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          無法登入\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "訂單/金流問題" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "訂單/金流問題"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          訂單/金流問題\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "頁面無法載入" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "頁面無法載入"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          頁面無法載入\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "星盤資料相關" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "星盤資料相關"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          星盤資料相關\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "資料錯置" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "資料錯置"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          資料錯置\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "修改資料失敗" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "修改資料失敗"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          修改資料失敗\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      class: { active: _vm.type == "其他" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.type = "其他"
                          _vm.downList = false
                        }
                      }
                    },
                    [_vm._v("\n          其他\n        ")]
                  )
                ])
              : _vm._e()
          ]
        ),
        _c("p", { staticClass: "form_title" }, [
          _vm._v("上傳截圖(" + _vm._s(_vm.imgLength) + "/4)")
        ]),
        _c(
          "div",
          { staticClass: "screenshot" },
          _vm._l(4, function(item) {
            return _c(
              "div",
              { key: item, staticClass: "screenshot_item_container" },
              [
                _c(
                  "label",
                  {
                    staticClass: "screenshot_item",
                    class: { active: _vm.imgArray["UpdateImg" + item] },
                    attrs: { for: "screenshot" + item }
                  },
                  [
                    _c("div", { staticClass: "screenshot_inner" }),
                    _vm.imgArray["UpdateImg" + item]
                      ? _c(
                          "div",
                          {
                            staticClass: "updateIMG",
                            class: { active: _vm.imgArray["UpdateImg" + item] }
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.imgArray["UpdateImg" + item] }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm.imgArray["UpdateImg" + item]
                      ? _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.imgArray["UpdateImg" + item] = null
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/report/close.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _c("img", {
                  staticClass: "screenshot_inner_img",
                  attrs: { src: require("../../assets/report/icon_add.svg") },
                  on: {
                    click: function($event) {
                      return _vm.toFocus(item)
                    }
                  }
                })
              ]
            )
          }),
          0
        ),
        _c("p", { staticClass: "screenshot_notice" }, [
          _vm._v("\n      提供完整資訊的問題截圖，能讓我們更快改善問題\n    ")
        ]),
        _c("p", { staticClass: "form_title" }, [_vm._v("詳細內容(選填)")]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.detail,
              expression: "detail"
            }
          ],
          staticClass: "detail",
          attrs: { cols: "30", placeholder: "能進一步告訴我們詳情嗎？" },
          domProps: { value: _vm.detail },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.detail = $event.target.value
            }
          }
        }),
        _c(
          "p",
          {
            staticClass: "form_notice",
            class: { OK: _vm.detail, OKK: _vm.detail.length > 100 }
          },
          [_vm._v("\n      完整的細節能讓我們更快釐清唷\n    ")]
        ),
        _c("div", { staticClass: "btn_group" }, [
          _c(
            "div",
            {
              staticClass: "btn pre",
              on: {
                click: function($event) {
                  return _vm.step1(0)
                }
              }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "div",
            {
              staticClass: "btn next",
              class: { active: _vm.imgLength > 0 },
              on: {
                click: function($event) {
                  return _vm.step1(1)
                }
              }
            },
            [_vm._v("\n        下一步\n      ")]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }