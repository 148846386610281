<template>
  <div>
    <div class="step0 main_container">
      <h3 class="main_title hidden-sm-and-down">進入管道</h3>
      <p class="main_description hidden-sm-and-down">請選擇您進入官網的管道</p>
      <h3 class="main_title hidden-md-and-up">問題回報</h3>
      <slot></slot>
      <hr class="mt-8" />
      <div class="enter_group">
        <h3 class="enter_title hidden-md-and-up">進入管道</h3>
        <p class="enter_description hidden-md-and-up">請選擇您進入星盤的管道</p>
        <div class="enter_item" @click="next('Line內建瀏覽器')">
          <div class="enter_logo">
            <img src="../../assets/report/icon_line.svg" />
          </div>
          <div class="enter_content">
            <p class="enter_title">Line</p>
            <p class="enter_description">從LINE選單進入星盤官網</p>
          </div>
        </div>
        <div class="enter_item" @click="next('一般web瀏覽器')">
          <div class="enter_logo">
            <img src="../../assets/report/icon_web.svg" />
          </div>
          <div class="enter_content">
            <p class="enter_title">一般瀏覽器</p>
            <p class="enter_description">從一般瀏覽器進入星盤官網</p>
          </div>
        </div>
      </div>
    </div>
    <p class="notice">
      為了保護您的權益，您所填寫的資料僅在必要時提供唐綺陽星盤工程團隊使用。
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    next(where) {
      this.$emit("step0", where);
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report.scss";
.step0 {
  .main_title {
    margin-top: 104px;
    @include pad {
      margin-top: 0;
    }
  }
}
.enter_group {
  margin-top: 48px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  @include pad {
    margin-top: 40px;
    margin-bottom: 72px;
    padding: 0;
  }
  //手機才有
  .enter_title {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 1.28px;
    color: #17445c;
    text-align: center;
  }
  .enter_description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.56px;
    color: #868686;
    text-align: center;
    margin: 6px 0 28px;
  }
  //都有
  .enter_item {
    width: 100%;
    height: 96px; //待會確認
    border-radius: 20px 35px 20px 35px;
    box-shadow: 4px 4px 9px 1px rgba(0, 0, 0, 0.2), -2px -2px 2px 2px #ffffff,
      -2px -2px 3px 1px #ffffff;
    background-color: #f0f2f4;
    padding: 12px 24px;
    margin-bottom: 28px;
    display: flex;
    cursor: pointer;
    @include pad {
      height: 88px;
    }
    .enter_logo {
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
      background-color: #f0f2f4;
      @include center;
      @include pad {
        width: 64px;
        height: 64px;
      }
      img {
        width: 52px;
      }
    }
    .enter_content {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      @include align(center, flex-start);
      .enter_title {
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: 0.72px;
        color: #17445c;
        margin: 0;
      }
      .enter_description {
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 1.28px;
        color: #868686;
        margin: 0;
        @include pad {
          font-size: 14px;
        }
      }
    }
  }
}
.notice {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.48px;
  color: #868686;
  text-align: center;
  margin: 0 auto 64px;
  padding: 0 25px;
  @include mobile {
    font-size: 12px;
    margin: 0 auto;
  }
}
</style>
